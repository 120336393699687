import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
//add Flex Layout
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { MainService } from './services/main.service';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Config } from '@forgerock/javascript-sdk';
import { environment } from '../environments/environment';
import { getStateDomainByDomain } from './utils';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FlexLayoutModule,
    NgOtpInputModule,
    NgbModule,
    HttpClientModule,
  ],
  providers: [
    MainService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    Config.set({
      serverConfig: {
        baseUrl: getStateDomainByDomain(
          environment.AM_URL,
          location.host,
          environment.production
        ),
        timeout: 10000,
      },
      tree: environment.DEFAULT_TREE,
      scope: environment.SCOPE,
      redirectUri: environment.REDIRECT_URL,
      realmPath: environment.REALM_PATH,
      tokenStore: environment.TOKEN_STORE as 'sessionStorage',
      clientId: environment.CLIENT_ID,
    });
  }
}
