import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { FlexLayoutModule } from '@angular/flex-layout';

import { NgOtpInputModule } from 'ng-otp-input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BaeLoginComponent } from './bae-login/bae-login.component';
import { BaeLeftcontentComponent } from './bae-leftcontent/bae-leftcontent.component';
import { BaeFooterComponent } from './bae-footer/bae-footer.component';
import { BaeVerifyAccountComponent } from './bae-verify-account/bae-verify-account.component';
import { BaeEmailVerifyComponent } from './bae-email-verify/bae-email-verify.component';
import { BaeCodeVerifyComponent } from './bae-code-verify/bae-code-verify.component';
import { BaeTermsConditionsComponent } from './bae-terms-conditions/bae-terms-conditions.component';
import { BaeHeaderComponent } from './bae-header/bae-header.component';
import { BaeInformationComponent } from './bae-information/bae-information.component';
import { BaeForgotPasswordComponent } from './bae-forgot-password/bae-forgot-password.component';
import { BaeForgotUseridComponent } from './bae-forgot-userid/bae-forgot-userid.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { CodeVerifyComponent } from './code-verify/code-verify.component';
import { LogoComponent } from './logo/logo.component';

@NgModule({
  declarations: [
    BaeLoginComponent,
    BaeLeftcontentComponent,
    BaeFooterComponent,
    BaeVerifyAccountComponent,
    BaeEmailVerifyComponent,
    BaeCodeVerifyComponent,
    BaeTermsConditionsComponent,
    BaeHeaderComponent,
    BaeInformationComponent,
    BaeForgotPasswordComponent,
    BaeForgotUseridComponent,
    LoginFormComponent,
    CodeVerifyComponent,
    LogoComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgOtpInputModule,
    NgbModule,
  ],
  exports: [ReactiveFormsModule],
  providers: [],
})
export class ComponentsModule {}
