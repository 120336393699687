import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BCBSState } from '../../../types';
import { getStateByDomain } from '../../utils';

@Component({
  selector: 'app-bae-header',
  templateUrl: './bae-header.component.html',
  styleUrls: ['./bae-header.component.css'],
})
export class BaeHeaderComponent implements OnInit {
  queryParams: Params = {};

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      this.queryParams = queryParams;
    });
  }

  get gotoParam() {
    return this.queryParams?.goto?.toLowerCase() || '';
  }
  get state(): BCBSState {
    return getStateByDomain(this.gotoParam);
  }
}
