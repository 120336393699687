<article class="baep-hero fiber-box">
  <div class="fiber-box fiber-text--size-3 fiber-d-inline">
    <h1 class="fiber-text--size-5 fiber-text--weight-bold fiber-mt-0">
      {{ name }} provides the control you need, when you need it.
    </h1>
    <h2 class="fiber-text--size-3 fiber-mt-4 fiber-mb-4 fiber-mr-4">
      {{ name }} helps you to manage your company's benefit plan and to complete
      your tasks, quickly and accurately.
    </h2>
    <h2 class="fiber-text--size-3 fiber-mt-4 fiber-mb-4 fiber-mr-4">
      Register today if you are a health plan administrator and do not have
      access to {{ name }}.
    </h2>
    <!-- end fiber box -->
  </div>
</article>
<!-- end fibercol  -->
