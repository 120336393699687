import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bae-verify-account',
  templateUrl: './bae-verify-account.component.html',
  styleUrls: ['./bae-verify-account.component.css'],
})
export class BaeVerifyAccountComponent implements OnInit {
  constructor(public formBuilder: FormBuilder, private router: Router) {}
  @Output() onSubmit = new EventEmitter<string>();
  @Input() choices: string[] = [];
  sendCodeForm: FormGroup = this.formBuilder.group({});

  ngOnInit() {
    this.sendCodeForm = this.formBuilder.group({
      sendCodeType: new FormControl(this.choices[0], Validators.required),
    });
  }

  handleSubmit() {
    this.onSubmit.emit(this.sendCodeForm.controls.sendCodeType.value);
  }

  private _getChoiceLabel(choice: string): string {
    switch (choice) {
      case 'Mobile':
        return 'Send me text message';
      case 'Email':
        return 'Send me an mail';
      default:
        return '';
    }
  }

  trackChoice(
    index: number,
    choice?: { name: string; value: string; label: string; example: string }
  ) {
    return choice ? choice.name : undefined;
  }

  get choiceOptions(): {
    name: string;
    value: string;
    label: string;
    example: string;
  }[] {
    return this.choices.map((choice) => {
      const [name, example] = choice.split(' :');
      return {
        name,
        value: choice,
        label: this._getChoiceLabel(name),
        example: example,
      };
    });
  }
}
