import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bae-footer',
  templateUrl: './bae-footer.component.html',
  styleUrls: ['./bae-footer.component.css'],
})
export class BaeFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
