<article class="fiber-box">
  <div>
    <h1
      class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3"
    >
      Verify Your Account.
    </h1>
    <h2 class="fiber-text--size-2 fiber-pt-1 fiber-pb-4">
      To keep your information safe, we’ll send you a one-time verification code
      to enter before you log in.
    </h2>

    <div class="fiber-box fiber-s-1 fiber-br-2 fiber-m-1 fiber-p-4">
      <p><b>Where should we send your code?</b></p>
      <form [formGroup]="sendCodeForm" (ngSubmit)="handleSubmit()">
        <div fxLayout="column">
          <div *ngFor="let choice of choiceOptions; trackBy: trackChoice">
            <input
              formControlName="sendCodeType"
              type="radio"
              [value]="choice.value"
              [id]="choice.name"
            />
            <label [for]="choice.name"
              ><span style="margin-left: 20px">{{ choice.label }}</span
              ><br />
              <span style="margin-left: 40px">{{ choice.example }}</span></label
            >
          </div>
          <div fxLayout="row" fxLayoutAlign="end">
            <button class="btn btn-primary col-4" style="border-radius: 50px">
              Send Code
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- end fiber box -->
</article>
