export const environment = {
  production: false,
  AM_URL: 'https://bim.{domain}/am',
  REDIRECT_URL: 'https://login-page-uat.cfaa.azr.hcsctest.net',
  REALM_PATH: 'b2b_legacy',
  DEFAULT_TREE: 'BIDM_UAT_MFA',
  TOKEN_STORE: 'sessionStorage',
  CLIENT_ID: 'LegacyApp_LoginPage_Uat',
  SCOPE: 'openid profile',
};
