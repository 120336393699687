import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaeLoginComponent } from './components/bae-login/bae-login.component';

const routes: Routes = [
  { path: 'b2bLogin', component: BaeLoginComponent },
  { path: '**', redirectTo: 'b2bLogin' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
