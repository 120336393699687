<div class="body">
  <app-bae-header></app-bae-header>
  <main>
    <div class="fiber-grid resp-margin">
      <div class="fiber-grid__row">
        <div
          class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5"
        >
          <app-bae-leftcontent></app-bae-leftcontent>
          <!-- end fibercol  -->
        </div>

        <div
          class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--offset-md-1 fiber-grid__col--md-5"
        >
          <app-login-form
            [loginForm]="loginForm"
            [loading]="loading"
            (onSubmit)="onSubmit()"
            *ngIf="step === 'login'"
          ></app-login-form>
          <app-code-verify
            (onSubmit)="handleCodeVerify($event)"
            [invalid]="codeVerifyInvalid"
            *ngIf="step === 'code-verify-prompt'"
          ></app-code-verify>
          <app-bae-verify-account
            (onSubmit)="handleVerifyChoice($event)"
            *ngIf="step === 'code-verify'"
            [choices]="choices"
          ></app-bae-verify-account>
          <!-- put a dialog box up on successful submission -->
          <div *ngIf="loading" id="bae-loading-dialog-box-wrapper">
            <div class="fiber-dialog fiber-p-8">
              <div
                class="fiber-dialog__container fiber-ta-center"
                role="dialog"
                data-qa-dialog="qa"
              >
                <h2
                  class="fiber-text--size-4 fiber-text--color-text-dark fiber-text--weight-bold fiber-pb-3"
                >
                  We are now logging you in....
                </h2>
                <div class="fiber-dialog__content">
                  <span
                    class="fiber-icon--name-refresh-line fiber-icon--size-4 fiber-icon-color-primary-blue"
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <!-- end fibercol  -->
        </div>

        <!-- end fiber row -->
      </div>

      <!-- end fiber grid-->
    </div>
  </main>
  <app-bae-footer></app-bae-footer>
</div>
