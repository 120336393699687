import { BCBSState } from '../../types';

export const getNameByState = (state: BCBSState): string => {
  if (state === 'IL') {
    return 'BlueCross BlueShield of Illinois';
  } else if (state === 'MT') {
    return 'BlueCross BlueShield of Montana';
  } else if (state === 'NM') {
    return 'BlueCross BlueShield of New Mexico';
  } else if (state === 'OK') {
    return 'BlueCross BlueShield of Oklahoma';
  } else if (state === 'TX') {
    return 'BlueCross BlueShield of Texas';
  } else {
    return 'Health Care Service Corporation';
  }
};

export const getStateByDomain = (domain: string): BCBSState => {
  if (domain.includes('bcbsil')) {
    return 'IL';
  } else if (domain.includes('bcbsnm')) {
    return 'NM';
  } else if (domain.includes('bcbstx')) {
    return 'TX';
  } else if (domain.includes('bcbsmt')) {
    return 'MT';
  } else if (domain.includes('bcbsok')) {
    return 'OK';
  } else {
    return 'default';
  }
};

export const getStateDomainByDomain = (
  domain: string,
  host: string,
  isProd: boolean
): string => {
  let result: string;
  let prefix = isProd ? '' : 'test.';
  if (host.includes('bcbsil')) {
    result = prefix + 'bcbsil.com';
  } else if (host.includes('bcbsnm')) {
    result = prefix + 'bcbsnm.com';
  } else if (host.includes('bcbstx')) {
    result = prefix + 'bcbstx.com';
  } else if (host.includes('bcbsmt')) {
    result = prefix + 'bcbsmt.com';
  } else if (host.includes('bcbsok')) {
    result = prefix + 'bcbsok.com';
  } else {
    result = isProd ? 'hcsc.net' : 'hcsctest.net';
  }
  return domain.replace(`{domain}`, result);
};
