import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  CallbackType,
  ChoiceCallback,
  FRAuth,
  FRCallback,
  FRLoginFailure,
  FRLoginSuccess,
  FRStep,
  NameCallback,
  PasswordCallback,
  StepType,
} from '@forgerock/javascript-sdk';
import { Title } from '@angular/platform-browser';
import { BCBSState } from '../../../types';
import { getNameByState, getStateByDomain } from '../../utils';

@Component({
  selector: 'app-bae-login',
  templateUrl: './bae-login.component.html',
  styleUrls: ['./bae-login.component.css'],
})
export class BaeLoginComponent implements OnInit {
  showPassword = false;
  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  currentStep: any;
  queryParams: Params = {};
  step: 'login' | 'code-verify-prompt' | 'code-verify' = 'login';
  codeVerifyInvalid: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    // private route: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute, // private alertService: AlertService
    private titleService: Title
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userId: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(40),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(40),
        ],
      ],
    });

    this.route.queryParams.subscribe((queryParams) => {
      this.queryParams = queryParams;
      this.titleService.setTitle(this.name);
      this.nextStep();
    });
  }

  async onSubmit() {
    this.loading = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }

    this.currentStep.callbacks.forEach((callback: FRCallback) => {
      switch (callback.getType()) {
        case CallbackType.NameCallback: {
          (callback as NameCallback).setName(this.loginForm.value.userId);
          break;
        }

        case CallbackType.PasswordCallback: {
          (callback as PasswordCallback).setPassword(
            this.loginForm.value.password
          );
          break;
        }

        default: {
          console.error('Unrecognized callback type.');
          break;
        }
      }
    });

    try {
      await this.nextStep(this.currentStep);
    } catch (e) {
      this.loginForm.controls.userId.setErrors({
        invalid: {
          invalid: true,
        },
      });
      this.loginForm.controls.password.setErrors({
        invalid: {
          invalid: true,
        },
      });
    } finally {
      this.submitted = false;
      this.loading = false;
    }
  }

  async handleCodeVerify(code: string) {
    this.codeVerifyInvalid = false;
    this.loading = true;
    this.submitted = true;
    this.currentStep.callbacks.forEach((callback: FRCallback) => {
      switch (callback.getType()) {
        case CallbackType.PasswordCallback: {
          (callback as PasswordCallback).setPassword(code);
          break;
        }

        default: {
          console.error('Unrecognized callback type.');
          break;
        }
      }
    });
    try {
      await this.nextStep(this.currentStep);
    } catch (e) {
      this.codeVerifyInvalid = true;
    } finally {
      this.submitted = false;
      this.loading = false;
    }
  }

  async handleVerifyChoice(value: string) {
    this.loading = true;
    this.submitted = true;
    this.currentStep.callbacks.forEach((callback: FRCallback) => {
      switch (callback.getType()) {
        case CallbackType.ChoiceCallback: {
          (callback as ChoiceCallback).setChoiceValue(value);
          break;
        }

        default: {
          console.error('Unrecognized callback type.');
          break;
        }
      }
    });
    try {
      await this.nextStep(this.currentStep);
    } catch (e) {
    } finally {
      this.submitted = false;
      this.loading = false;
    }
  }
  /**
   *
   *
   * @param {FRStep} step
   * @memberof MainComponent
   */
  async nextStep(step?: FRStep) {
    const nextStep = await FRAuth.next(step as FRStep);
    await this.handleStep(nextStep);
  }

  /**
   *
   *
   * @param {*} err
   * @memberof MainComponent
   */
  handleError(err: any) {
    console.error(err);
  }

  get gotoParam() {
    return this.queryParams?.goto?.toLowerCase() || '';
  }
  get state(): BCBSState {
    return getStateByDomain(this.gotoParam);
  }

  get name() {
    return getNameByState(this.state);
  }

  buildAuthForm(callbacks: FRCallback[]): void {
    this.step = 'login';
    this.loginForm.setValue({
      userId: '',
      password: '',
    });
  }

  get choices(): string[] {
    return (
      (this.currentStep as FRStep).callbacks[0] as ChoiceCallback
    ).getChoices();
  }

  async handleStep(
    step: FRStep | FRLoginSuccess | FRLoginFailure
  ): Promise<any> {
    {
      // TODO: After every round of authentication, form control needs to be cleared.
      switch (step.type as StepType) {
        case StepType.Step: {
          this.currentStep = step;
          if ((step as FRStep).callbacks.length === 1) {
            if (
              (step as FRStep).callbacks[0].getType() ===
              CallbackType.ChoiceCallback
            ) {
              this.step = 'code-verify';
            } else {
              this.step = 'code-verify-prompt';
            }
          } else {
            this.buildAuthForm((step as FRStep).callbacks);
          }
          break;
        }
        case StepType.LoginFailure: {
          throw step;
          break;
        }
        case StepType.LoginSuccess: {
          if (this.queryParams.goto) {
            window.location.assign(this.queryParams.goto);
          }
          break;
        }
        default: {
          // this.router.navigate(['error']);
          break;
        }
      }
    }
  }
}
