import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { ObjectUnsubscribedError, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  termsUrl: string =
    'https://api.hcsctest.net/aem/content/v1/content/dam/hcsc/bae/tou.generic.json/bae/states/il1';
  httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',

      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET',
      'Access-Control-Allow-Headers': 'application/json',
    }),
    responseType: 'text' as 'json',
  };
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
  });

  private url = 'http://jsonplaceholder.typicode.com/posts';
  constructor(private httpClient: HttpClient) {}
  getTermsData() {
    return this.httpClient.get(this.termsUrl);
  }

  getPosts() {
    return this.httpClient.get(this.url);
  }
}
