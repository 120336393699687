<article class="fiber-box">
  <div>
    <h3><b>Verify your account.</b></h3>
    <br />
    <div class="fiber-box fiber-s-1 fiber-br-2 fiber-m-1 fiber-p-4">
      <p>Enter your one-time verification code</p>
      <span
        *ngIf="invalid"
        class="invalid-feedback fiber-mb-3"
        style="display: block"
      >
        Confirmation code is invalid
      </span>
      <div fxLayout="column">
        <div class="fiber-mb-3">
          <ng-otp-input
            (onInputChange)="onOtpChange($event)"
            [config]="{
              length: 8,
              isPasswordInput: true,
              inputStyles: { width: '30px', height: '40px' }
            }"
          ></ng-otp-input>
        </div>
        <div fxLayout="column" fxLayoutAlign="end end">
          <button
            class="btn btn-primary col-4"
            style="border-radius: 50px"
            (click)="handleSubmit()"
          >
            Send Code
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- end fiber box -->
</article>
