import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-code-verify',
  templateUrl: './code-verify.component.html',
  styleUrls: ['./code-verify.component.css'],
})
export class CodeVerifyComponent implements OnInit {
  isChecked = false;
  @Output() onSubmit = new EventEmitter<string>();
  @Input() invalid: boolean = false;
  value: string = '';
  constructor() {}

  ngOnInit(): void {}

  onOtpChange(code: any) {
    this.value = code;
  }

  handleSubmit() {
    if (this.value.length === 8) {
      this.onSubmit.emit(this.value);
    }
  }
}
