<header class="fiber-mt-5 fiber-mb-5 fiber-mr-3">
  <div class="fiber-grid resp-margin">
    <div class="fiber-grid__row">
      <div class="fiber-grid__col fiber-grid__col--xs-6 fiber-grid__col--md-5">
        <div class="fiber-bcbs-logo">
          <span class="fiber-bcbs-logo__sr-only"
            >Blue Cross Blue Shield of Illinois</span
          >
          <logo [state]="state"></logo>
        </div>
      </div>
    </div>
  </div>
</header>
