import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { BCBSState } from '../../../types';
import { getNameByState, getStateByDomain } from '../../utils';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements OnInit {
  @Input() loginForm!: FormGroup;
  submitted = false;
  @Input() loading = false;
  showPassword = false;
  @Output() onSubmit = new EventEmitter<void>();
  private queryParams: Params = {};

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      this.queryParams = queryParams;
    });
  }
  get gotoParam() {
    return this.queryParams?.goto?.toLowerCase() || '';
  }
  get state(): BCBSState {
    return getStateByDomain(this.gotoParam);
  }

  get name() {
    return getNameByState(this.state);
  }

  handleSubmit() {
    this.onSubmit.emit();
  }

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }
}
