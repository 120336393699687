<article class="fiber-box">
  <fieldset class="fiber-fieldset fiber-bw-0">
    <legend
      class="fiber-fieldset__legend fiber-mb-3 fiber-text--size-5 fiber-text--weight-bold"
    >
      Log In
    </legend>

    <form
      class="baep-login-form fiber-form"
      aria-labelledby="Baep_Login_Form"
      [formGroup]="loginForm"
      (ngSubmit)="handleSubmit()"
    >
      <!-- email input -->
      <div
        class="fiber-box fiber-s-1 fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3"
      >
        <div class="form-group">
          <label for="userId"><b>User ID</b></label>
          <input
            type="text"
            formControlName="userId"
            class="form-control"
            [ngClass]="{ 'is-invalid': loginForm.touched && f.userId.errors }"
          />
          <div
            *ngIf="f.userId.touched && f.userId.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.userId.errors.required">User ID is required</div>
            <div *ngIf="f.userId.errors.minlength">
              User ID must be at least
              {{ f.userId.errors.minlength.requiredLength }} characters
            </div>
            <div *ngIf="f.userId.errors.maxlength">
              User ID must not exceed
              {{ f.userId.errors.maxlength.requiredLength }} characters
            </div>
            <div *ngIf="f.userId.errors.invalid">User ID is invalid</div>
          </div>
        </div>

        <div class="form-group">
          <label for="password"><b>Password</b></label>
          <div class="inner-addon right-addon">
            <div class="input-group mx-auto">
              <input
                type="password"
                formControlName="password"
                class="form-control"
                [ngClass]="{
                  'is-invalid': f.password.touched && f.password.errors
                }"
                [type]="showPassword ? 'text' : 'password'"
              />

              <div>
                <i
                  alt="show"
                  class="far fa-eye eye-show"
                  (click)="showPassword = !showPassword"
                  [class.hide]="showPassword"
                ></i>

                <i
                  alt="hide"
                  class="far fa-eye-slash eye-hide"
                  (click)="showPassword = !showPassword"
                  [class.hide]="!showPassword"
                ></i>
              </div>
              <div
                *ngIf="f.password.touched && f.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.password.errors.required">
                  Password is required
                </div>
                <div *ngIf="f.password.errors.minlength">
                  Password must be at least
                  {{ f.password.errors.minlength.requiredLength }} characters
                </div>
                <div *ngIf="f.password.errors.maxlength">
                  Password must not exceed
                  {{ f.password.errors.maxlength.requiredLength }} characters
                </div>
                <div *ngIf="f.password.errors.invalid">Password is invalid</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- if they click on login, send them to bae login, screen two, bae login -->
      <button
        name="baep-login"
        class="fiber-button fiber-button--size-1 fiber-button--primary fiber-button--block fiber-text--weight-bold fiber-mb-3"
      >
        <span
          class="fiber-icon--size-1 fiber-icon--color-white fiber-icon--name-lock-solid fiber-mr-1"
        >
        </span>
        <span class="fiber-button__text">Log In</span>
      </button>
      <!-- end form -->
    </form>
    <div style="margin-top: 20px">
      <div class="fiber-box fiber-bc-gray-5 fiber-d-block fiber-pt-5">
        <p
          class="fiber-text fiber-text--size-1 fiber-text--color-text-dark fiber-text--weight-normal fiber-text--style-normal fiber-ta-left"
        >
          {{ name }} is a secure online tool for the sole use of our Employer
          Groups. It contains personal and confidential information protected by
          law. We routinely monitor all online activity. By submitting your
          email address and password, you certify to us that you are the
          intended customer or you have lawful authority to review that
          customer's confidential information. Any use of this service by any
          other person is a criminal offense and is strictly prohibited.
          Violators are subject to civil or criminal penalties.
        </p>
      </div>
    </div>
  </fieldset>

  <!-- end fiber box -->
</article>
