<footer class="fiber-bg-blue-primary fiber-mt-7">
  <div class="fiber-grid resp-margin">
    <div class="fiber-grid__row">
      <div
        class="fiber-grid__col fiber-grid__col--md-12 fiber-mt-2 fiber-mb-2 fiber-pt-5 fiber-pb-5 fiber-text--color-white"
      >
        <p class="fiber-link--size-1">
          <a class="fiber-link fiber-link--inverse fiber-link--inline" href="#"
            >Legal &amp; Privacy</a
          >
        </p>
        <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3">
          A Division of Health Care Service Corporation, a Mutual Legal Reserve
          Company, an Independent Licensee of the Blue Cross and Blue Shield
          Association
        </p>
        <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3">
          © Copyright 2021 Health Care Service Corporation. All Rights Reserved.
        </p>
        <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3">
          <span
            class="fiber-icon--name-document-pdf-solid fiber-icon--size-1 fiber-mr-1"
          ></span
          >File is in portable document format (PDF). To view this file, you may
          need to install a PDF reader program. Most PDF readers are a free
          download. One option is Adobe® Reader® which has a built-in screen
          reader. Other Adobe accessibility tools and information can be
          downloaded at http://access.adobe.com
          <span
            class="fiber-icon--name-external-link fiber-icon--size-1 fiber-icon--color-white"
          ></span>
        </p>
        <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3">
          <span
            class="fiber-icon--name-external-link fiber-icon--size-1 f fiber-mr-1"
          ></span
          >You are leaving this website/app (“site”). This new site may be
          offered by a vendor or an independent third party. The site may also
          contain non-Medicare related information. In addition, some sites may
          require you to agree to their terms of use and privacy policy.
        </p>
      </div>
    </div>
  </div>
</footer>
