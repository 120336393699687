import { Component, OnInit } from '@angular/core';
import { getNameByState, getStateByDomain } from '../../utils';
import { ActivatedRoute, Params } from '@angular/router';
import { BCBSState } from '../../../types';

@Component({
  selector: 'app-bae-leftcontent',
  templateUrl: './bae-leftcontent.component.html',
  styleUrls: ['./bae-leftcontent.component.css'],
})
export class BaeLeftcontentComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  queryParams: Params = {};
  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      this.queryParams = queryParams;
    });
  }
  get gotoParam() {
    return this.queryParams?.goto?.toLowerCase() || '';
  }
  get state(): BCBSState {
    return getStateByDomain(this.gotoParam);
  }

  get name() {
    return getNameByState(this.state);
  }
}
