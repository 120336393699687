import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BCBSState } from '../../../types';

@Component({
  selector: 'logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css'],
})
export class LogoComponent implements OnInit {
  @Input() state: BCBSState = 'IL';

  constructor(private router: Router) {}

  ngOnInit(): void {}
}
